


import AppConfig from '../config';
import { User, NewUser } from '../models/UserModel';
import ApiClient from './apiClient';

const UserService = () => {

    const { get, post, deleteRecords, put } = ApiClient()

    const getAllUsers = (): Promise<User[]> => {
        return new Promise<User[]>((resolve, reject) => {
            get<User[]>(`${AppConfig.UiApiBasePath}/users`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const getUser = (id: string): Promise<User> => {
        let encodedID = encodeAuth0ID(id);
        return new Promise<User>((resolve, reject) => {
            get<User>(`${AppConfig.UiApiBasePath}/users/${encodedID}`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const createNewUser = (user: NewUser): Promise<User> => {
        return new Promise<User>((resolve, reject) => {
            post<User>(`${AppConfig.UiApiBasePath}/users`, user).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const deleteUser = (id: string): Promise<string> => {
        let encodedID = encodeAuth0ID(id);
        return new Promise<string>((resolve, reject) => {
            post<string>(`${AppConfig.UiApiBasePath}/users/${encodedID}/delete`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const resetUserPassword = (id: string): Promise<string> => {
        let encodedID = encodeAuth0ID(id);
        return new Promise<string>((resolve, reject) => {
            post<string>(`${AppConfig.UiApiBasePath}/users/${encodedID}/passwordreset`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const userIsAdmin = (): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            post<string>(`${AppConfig.UiApiBasePath}/isadmin`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const encodeAuth0ID = (id: string): string => {
        var idArray = id.split("|");
        if(idArray.length === 2){
            return (idArray[0] + '%7C' + idArray[1]);
        }
        return id;
    }

    return{
        getAllUsers,
        getUserDetail: getUser,
        createNewUser,
        deleteUser,
        resetUserPassword,
        userIsAdmin
    }

}

export default UserService;