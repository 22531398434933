import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Table, 
    TableBody, TableContainer, TableHead, TableRow, Accordion, AccordionDetails, AccordionSummary, TablePagination } from "@material-ui/core";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { useHistory } from "react-router-dom";
import SourceConnectionService from "../../services/SourcesConnectionService";
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import ErrorIcon from '@material-ui/icons/Error'
import { Alert, AlertTitle } from "@material-ui/lab";
import MessageBox from "../../components/message/MessageBox";
import { SourceConnection } from "../../models/SourceConnection";
import UserService from "../../services/UserService";
import { User } from "../../models/UserModel";
import CreateUser from "../../components/management/CreateUser";
import RemoveUser from "../../components/management/RemoveUser";
import { grey } from "@material-ui/core/colors";
import moment from 'moment';


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#7CCCEE',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const OptionsTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#7CCCEE',
        color: theme.palette.common.white,
    },
    root: {
        borderLeftWidth: 1,
        borderLeftColor: theme.palette.grey[300],
        borderLeftStyle: "solid",
      },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    table: {
        minWidth: 700,
    },
    primaryButton: {
        margin: theme.spacing(1),
        backgroundColor: '#349CC9',
        color: 'white',
        minWidth: 120
    },
    secondaryButton: {
        marginRight: 10,
        backgroundColor: '#7CCCEE',
        color: 'white',
        minWidth: 120,
        fontSize: 11
    },
    deleteButton: {        
        backgroundColor: '#FF7A5A',
        color: 'white',
        minWidth: 120,
        fontSize: 11
    }
  }));

const UserManagement: FC = (): ReactElement => {
    
    const { getAllUsers, getUserDetail, resetUserPassword } = UserService();

    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState<{ title, message, severity }>();

    const [users, setUsers] = useState<User[]>();
    const [userDetail, setUserDetail] = useState<User>();

    const [addingUser, setAddingUser] = useState(false);
    const [removingUser, setRemovingUser] = useState(false);

    const [expandedAccordion, setExpandedAccording] = useState<string>();
    const [selectedUser, setSelectedUser] = useState<User>();

    const [loadingDetails, setLoadingDetails] = useState(false);

    const [page, setPage] = useState(0);

    const loadUsers = () => {
        setLoading(true);
        setSelectedUser(null);
        setExpandedAccording(null);
        getAllUsers().then(res => {
            setUsers(res);
        }).finally(() => {
            setLoading(false);
        });
    }

    const resetPassword = (userID: string) => {
        resetUserPassword(userID).then(res => {
            setAlertMessage({title: 'Password Reset', message: 'A password reset link was sent to ' + selectedUser.email, severity: 'success'});
        }).catch(error => {
            setAlertMessage({title: 'Password Reset Failed', message: error.message, severity: 'error'});
        })
        
    }

    const handleAccordionChange = ( user: User) => (event, isExpanded) => {
        setSelectedUser(null);
        setExpandedAccording(null);
        if(isExpanded){
            setLoadingDetails(true);
            getUserDetail(user.user_id).then(res => {
                setUserDetail(res);
            }).catch(error => {
                setUserDetail(null);
            }).finally(() => {
                setExpandedAccording(user.user_id);
                setSelectedUser(user);
                setLoadingDetails(false);
                //console.log(user)
            });
        }
      };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
     };

     useEffect(() => {
        setSelectedUser(null);
        setExpandedAccording(null);
     }, [page]);

    useEffect(() => {
        loadUsers();
    }, []);

    return (
        <div>
            <Grid container spacing={3} style={{minWidth: '1275px'}}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <div style={{
                            fontSize: 22,
                            marginBottom: 20
                        }}>
                            User Management
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>
                            <div>
                                
                            </div>
                            <div style={{
                                width: '20%',
                                textAlign: 'right',
                                textTransform: 'uppercase'
                            }}>
                                <Button
                                    variant="contained"
                                    className={classes.primaryButton}
                                    endIcon={<>+</>}
                                    onClick={() => {setAddingUser(true)}}
                                >
                                    New User
                                </Button>
                            </div>                            
                        </div>                      
                    </Paper>
                </Grid>
                <Grid item xs={8} style={{minWidth: '835px'}}>
                    {!loading && users && users.slice(page * 10, page * 10 + 10).map((user) => (
                        <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expandedAccordion === user.user_id} onChange={handleAccordionChange(user)}>
                            <AccordionSummary>
                                <div style={{
                                    width: '50%',
                                    textAlign: 'left',
                                }}>
                                    {user.name}
                                </div>
                                <div style={{
                                    color: '#2e4d59',
                                    width: '50%',
                                    textAlign: 'right',
                                }}>
                                    {user.email}
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{
                                    display: 'inline',
                                    alignItems: 'normal',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        width: '100%',
                                        marginBottom: 10
                                    }}>
                                        <div style={{color: 'grey', width: '10%'}}>First Name:</div>
                                        <div style={{color: '#349CC9', width: '18%'}}>{userDetail?.given_name}</div>
                                        <div style={{color: 'grey', width: '10%'}}>Last Name:</div>
                                        <div style={{color: '#349CC9', width: '18%'}}>{userDetail?.family_name}</div>
                                        <div style={{color: 'grey', width: '8%'}}>Created:</div>
                                        <div style={{color: '#349CC9', width: '20%'}}>{userDetail && moment(userDetail.created_at).format("YYYY-MM-DD hh:mm:ss")}</div>
                                        <div style={{color: 'grey', width: '8%'}}>Blocked:</div>
                                        <div style={{color: '#349CC9'}}>{(userDetail?.blocked && 'true') || 'false'}</div>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        width: '100%'
                                    }}>
                                        <div style={{color: 'grey', width: '16%'}}>Number of Logins:</div>
                                        <div style={{color: '#349CC9', width: '5%'}}>{userDetail?.logins_count}</div>
                                        <div style={{color: 'grey', width: '10%'}}>Last Login:</div>
                                        <div style={{color: '#349CC9', width: '20%'}}>{userDetail && userDetail.last_login && moment(userDetail.last_login).format("YYYY-MM-DD hh:mm:ss")}</div>
                                        <div style={{color: 'grey', width: '12%'}}>Last Login IP:</div>
                                        <div style={{color: '#349CC9'}}>{userDetail?.last_ip}</div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={users?.length || 0}
                        rowsPerPage={10}
                        page={page}
                        onPageChange={handleChangePage}
                        //onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    {loading && 'Loading Users...'}
                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.paper}>
                        <div style={{
                            fontSize: 16,
                            marginBottom: 10
                        }}>
                            {selectedUser?.name || 'No user selected'}</div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            //justifyContent: 'space-between',
                            width: '100%'
                        }}>
                            
                                <Button
                                    variant="contained"
                                    size="small"
                                    disabled={!selectedUser}
                                    className={classes.secondaryButton}
                                    onClick={() => {resetPassword(selectedUser.user_id)}}
                                >
                                    Reset Password
                                </Button>
                            
                                <Button
                                    variant="contained"
                                    size="small"
                                    disabled={!selectedUser}
                                    className={classes.deleteButton}
                                    onClick={() => {setRemovingUser(true)}}
                                >
                                    Delete
                                </Button>
                            
                        </div>
                    </Paper>
                </Grid>
            </Grid>

            <CreateUser 
                show={addingUser} 
                setAlert={setAlertMessage} 
                onCompletion={()=>{loadUsers()}} 
                onClose={()=>{setAddingUser(false)}}/>

            <RemoveUser 
                show={removingUser}
                setAlert={setAlertMessage} 
                user={selectedUser}
                onCompletion={()=>{loadUsers()}} 
                onClose={()=>{setRemovingUser(false)}}/>

            <MessageBox
                onOk={() => {
                    setAlertMessage(undefined);
                }}
                onWondowClose={() => {
                    setAlertMessage(undefined);
                }}
                show={alertMessage != undefined}
                title={alertMessage?.title}
            >
                <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
            </MessageBox>

        </div>
    )
}

export default UserManagement;