import React, { FC, ReactElement, useState, PropsWithChildren } from 'react';

export interface UserContextResult {
    setLoggedUser: (authUser: any) => void;
    authUser?: any;
}

export const UserContext = React.createContext<UserContextResult>({
    setLoggedUser: () => {
        throw new Error('UserContext has not been initialized');
    },
    authUser: undefined,
});

export const UserProvider: FC<PropsWithChildren<any>> = ({ children }) => {
    const [authUser, setUser] = useState<any>();
    const setLoggedUser = (_user: any): void => {
        setUser(_user);
    };

    return (
        <UserContext.Provider
            value={{
                setLoggedUser,
                authUser,
            }
            }
        >
            {children}
        </UserContext.Provider>
    );
};
