import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { FC, ReactElement } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Icon from '@material-ui/core/Icon';
import { Source, SourceField } from "../../models/Source";
import { useEffect, useState } from "react";
import SourcesService from "../../services/SourcesServices";
import SourceConnectionService from "../../services/SourcesConnectionService";
import { useHistory, useParams } from "react-router";
import { SourceConnection } from "../../models/SourceConnection";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#349CC9',
        color: 'white'
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    fullWidthField: {
        width: '100%'
    }
}));

const SourceConnectionEditView: FC = (): ReactElement => {
    
    const {
        getSourceConnection,
        save,
    } = SourceConnectionService();

    const { getSources } = SourcesService();

    const { id } = useParams<{ id?: string }>();
    const classes = useStyles();
    const params = useParams() as any;
    const [sources, setSources] = useState<Source[]>();
    const [sourceConnection, setSourceConnection] = useState<SourceConnection>();
    const [name, setName] = useState('');
    const [selectedSource, setSelectedSource] = useState<Source>();
    const history = useHistory();
    
    const getId = () => {
        if (id) {
            return Number.parseInt(id);
        }
        return 0;
    }

    const loadSources = (sourceConnection?: SourceConnection) => {
        if (sourceConnection) {
            setSourceConnection(sourceConnection);
            setName(sourceConnection.name)
            setSelectedSource({
                ...sourceConnection.source,
                fields: sourceConnection.source.fields.map(f => {
                    return {
                        ...f,
                        value: sourceConnection.fields.find(sf => sf.sourceField.id === f.id) ? sourceConnection.fields.find(sf => sf.sourceField.id === f.id).value : ''
                    }
                })
            });
        }
        getSources().then(res => {
            let returnedsources = res;
            if (sourceConnection) {
                returnedsources = returnedsources.map(rs => {
                    return {
                        ...rs,
                        fields: rs.fields.map(rf => {
                            return {
                                ...rf,
                                value: sourceConnection.fields && sourceConnection.fields.find(scf => scf.sourceField.id == rf.id) ? sourceConnection.fields.find(scf => scf.sourceField.id == rf.id).value : ''
                            }
                        })
                    }
                })
            }
            setSources(returnedsources);
        })
    }

    useEffect(() => {
        if (id) {
            getSourceConnection(getId()).then(connection => {
                loadSources(connection);
            });
        } else {
            loadSources();
        }
    }, []);

    const getTextField = (s: SourceField) => {
        return (<TextField 
            className={classes.fullWidthField} 
            id="outlined-basic" 
            label={s.placeholder}
            variant="outlined"
            value={s.value}
            type={s.isProtected? 'password' : 'text'}
            onChange={(event) => {
                setSelectedSource({
                    ...selectedSource,
                    fields: selectedSource.fields.map(f => {
                        return {
                            ...f,
                            value: f.id === s.id ? event.target.value : f.value
                        }
                    })
                })
            }}
            placeholder={s.placeholder} />)
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <form className={classes.root} noValidate autoComplete="off">
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Connector</InputLabel>
                            {sources && <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={selectedSource ? selectedSource.id : null}
                                onChange={(e) => setSelectedSource(sources.find(s => s.id === e.target.value))}
                                label="Name"
                            >
                                {sources && sources.map(s => (
                                    <MenuItem value={s.id}>{s.name}</MenuItem>
                                ))}
                            </Select>}
                        </FormControl>
                        <TextField 
                            className={classes.fullWidthField} 
                            id="outlined-basic" 
                            label="Connection Name"
                            variant="outlined"
                            value={name}
                            onChange={(event) => {
                                setName(event.target.value);
                            }}
                            placeholder="Connection Name" />
                        {selectedSource && selectedSource.fields.map(s => {
                            switch (s.type.toLowerCase()) {
                                case 'string':
                                    return getTextField(s);
                                default:
                                    return getTextField(s)
                            }
                        })}
                    </form>
                </Grid>
                <Grid item xs={4}>
                </Grid>
            </Grid>
            <div>
                <Button 
                    variant="contained" 
                    className = {classes.button}
                    onClick={() => {
                        save({
                            id: getId(),
                            sourceId: selectedSource.id,
                            fields: selectedSource.fields.map(f => {
                                return {
                                    id: sourceConnection?.fields.find(sf => sf.sourceField.id === f.id) ? sourceConnection.fields.find(sf => sf.sourceField.id === f.id).id : 0,
                                    sourceFieldId: f.id,
                                    value: f.value
                                }
                            }),
                            name
                        }).then(res => {
                            history.push('/source-connections')
                        })
                    }}>
                    Save
                </Button>
            </div>
        </div>
    )
}

export default SourceConnectionEditView;