import { Button, Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { useHistory, useParams } from "react-router-dom";
import JobsServices from "../../services/JobsServices";
import { LogTable } from "../../models/LogTable";
import moment from 'moment'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#7CCCEE',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#7CCCEE',
        color: 'white'
    }
  }));

const LogsListDetailsView: FC = (): ReactElement => {
    
    const { getLogs, getException } = JobsServices();
    const { id } = useParams<{ id?: string }>();
    const classes = useStyles();
    const history = useHistory();

    const [openExceptionView, setOpenExceptionView] = useState(false);
    const [exceptionMessage, setExceptionMessage] = useState<string>();

    //const [jobTotal, setJobTotal] = useState<Integration>()
    const [jobTables, setJobTables] = useState<LogTable[]>();
    
    useEffect(() => {
        getLogs(Number(id)).then(res => {
            setJobTables(res);
        });
    }, []);

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={10}>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="medium" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell >Source Table</StyledTableCell>
                            <StyledTableCell >Destination Table</StyledTableCell>
                            <StyledTableCell style={{width: '10%'}}>Load Type</StyledTableCell>
                            <StyledTableCell style={{width: '11%'}}>Rows Migrated</StyledTableCell>
                            <StyledTableCell style={{width: '14%'}}>Start Time</StyledTableCell>
                            <StyledTableCell style={{width: '14%'}}>End Time</StyledTableCell>
                            <StyledTableCell style={{width: '5%'}}>Success</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {jobTables && jobTables.map((row) => (
                            <StyledTableRow key={row.destinationTable}>
                                <StyledTableCell component="th" scope="row">
                                    {row.sourceTableName}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {row.destinationTable}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align="center">
                                    {row.loadType}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align="right">
                                    {row.rowsUploaded}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {moment(row.startTime).utc().format(`yyyy/MM/DD HH:mm:ss`)}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {moment(row.endTime).utc().format(`yyyy/MM/DD HH:mm:ss`)}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align="center">
                                    {(!row.hasException && <CheckIcon style={{color: 'green'}} />) 
                                    || (<Tooltip title="View Exception" placement="right-start">
                                            <button
                                                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                                                onClick={() => {
                                                    getException(Number(row.id)).then(res => {
                                                        setExceptionMessage(res[0].exception_message)
                                                        setOpenExceptionView(true)
                                                    })
                                                }}
                                            >
                                                <ClearIcon style={{ color: 'red' }}/>
                                            </button>
                                        </Tooltip>)}                                    
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={openExceptionView}
                keepMounted
                onClose={() => {
                    setOpenExceptionView(false);
                }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {exceptionMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenExceptionView(false);                        
                    }} color="primary"> OK </Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default LogsListDetailsView;