import React, { FC, ReactElement, ReactNode } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyProvider = FC<any>;

// eslint-disable-next-line @typescript-eslint/ban-types
type ProviderWithProps = [AnyProvider, {}];

export interface CombineProvidersProps {
    children: React.ReactNode;
    providers: (AnyProvider | ProviderWithProps)[];
}

export function CombineProviders({
    children,
    providers,
    ...rest
}: CombineProvidersProps): ReactElement {
    if (providers.length < 1) {
        throw new Error('At least one provider is required');
    }
    let result: ReactElement | undefined;
    let nextChildren = children;
    for (let i = providers.length - 1; i >= 0; i--) {
        const [type, props] = (providers[i] instanceof Array
            ? providers[i]
            : [providers[i], {}]) as ProviderWithProps;
        result = React.createElement(
            type,
            {
                ...(i === 0 ? rest : {}),
                ...props,
            },
            nextChildren,
        );
        nextChildren = result;
    }
    return result as ReactElement;
}
