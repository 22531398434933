import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import HomeIcon from '@material-ui/icons/Home';
import AppsIcon from '@material-ui/icons/Apps';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import StorageIcon from '@material-ui/icons/Storage';
import SettingsInputCompositeIcon from '@material-ui/icons/SettingsInputComposite';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import { NavLink } from 'react-router-dom';

export const mainListItems = (
  <div>
    <NavLink to="/" activeClassName="selected" exact>
      <ListItem button>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" style={{ color: '#349CC9' }}/>
      </ListItem>
    </NavLink>

    {/* <NavLink to="/">
      <ListItem button>
        <ListItemIcon>
          <AppsIcon />
        </ListItemIcon>
        <ListItemText primary="Apps" />
      </ListItem>
    </NavLink>

    <NavLink to="/">
      <ListItem button>
        <ListItemIcon>
          <ChromeReaderModeIcon />
        </ListItemIcon>
        <ListItemText primary="Audit" />
      </ListItem>
    </NavLink> */}

    {/* <NavLink to="/sources" activeClassName="selected">
      <ListItem button>
        <ListItemIcon>
          <StorageIcon />
        </ListItemIcon>
        <ListItemText primary="Connectors" />
      </ListItem>
    </NavLink> */}

    <NavLink to="/source-connections" activeClassName="selected">
      <ListItem button>
        <ListItemIcon>
          <StorageIcon />
        </ListItemIcon>
        <ListItemText primary="Connections" style={{ color: '#349CC9' }}/>
      </ListItem>
    </NavLink>

    <NavLink to="/sources-meta" activeClassName="selected">
      <ListItem button>
        <ListItemIcon>
          <StorageIcon />
        </ListItemIcon>
        <ListItemText primary="Mappings" style={{ color: '#349CC9' }}/>
      </ListItem>
    </NavLink>

    <NavLink to="/jobs" activeClassName="selected">
      <ListItem button>
        <ListItemIcon>
          <StorageIcon />
        </ListItemIcon>
        <ListItemText primary="Jobs" style={{ color: '#349CC9' }}/>
      </ListItem>
    </NavLink>

    <NavLink to="/logs" activeClassName="selected">
      <ListItem button>
        <ListItemIcon>
          <StorageIcon />
        </ListItemIcon>
        <ListItemText primary="Log" style={{ color: '#349CC9' }}/>
      </ListItem>
    </NavLink>

    {/* 

    <NavLink to="/source-objects">
      <ListItem button>
        <ListItemIcon>
          <StorageIcon />
        </ListItemIcon>
        <ListItemText primary="Sources Objects" />
      </ListItem>
    </NavLink>

    <NavLink to="/">
      <ListItem button>
        <ListItemIcon>
          <SettingsInputCompositeIcon />
        </ListItemIcon>
        <ListItemText primary="Meta Model" />
      </ListItem>
    </NavLink>

    <NavLink to="/">
      <ListItem button>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItem>
    </NavLink>

    <NavLink to="/">
      <ListItem button>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem>
    </NavLink> */}
  </div>
);

export const secondaryListItems = (
  <div>
    {/* <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem> */}
  </div>
);

export const adminListItems = (
  <div>
    <NavLink to="/users" activeClassName="selected">
      <ListItem button>
        <ListItemIcon>
          <StorageIcon />
        </ListItemIcon>
        <ListItemText primary="User Management" style={{ color: '#349CC9' }}/>
      </ListItem>
    </NavLink>
  </div>
);