import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { FC, ReactElement } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';


const useStyles = makeStyles((theme) => ({
    root: {
        width: ''
    },
}));

interface MessageBoxProps {
    show: boolean;
    children: React.ReactNode;
    title: string;
    onWondowClose: () => void;
    onOk: () => void;
}

const MessageBox: FC<MessageBoxProps> = ({
    show = false,
    children,
    onWondowClose,
    onOk,
    title
}): ReactElement => {
    const classes = useStyles();

    return (
        <Dialog
            open={show}
            keepMounted
            onClose={() => {
                if (onWondowClose) {
                    onWondowClose()
                }
            }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    if (onOk) {
                        onOk()
                    }
                }} color="primary"> Ok </Button>
            </DialogActions>
        </Dialog>
    )
}

export default MessageBox;