import { Button, Fab, ButtonGroup, Popper, Grow, ClickAwayListener, MenuList, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, 
    FormControl, Grid, InputLabel, MenuItem, Paper, TablePagination, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Divider } from "@material-ui/core";
import React, { FC, ReactElement } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Icon from '@material-ui/core/Icon';
import { Source } from "../../models/Source";
import { useEffect, useState } from "react";
import { RouteChildrenProps, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { MetaMapping } from "../../models/MetaMapping";
import MetaMappingService from "../../services/MetaMappingService";
import ClientTableService from "../../services/ClientTableService";
import { SourceTable } from "../../models/SourceTable";
import SourceConnectionService from "../../services/SourcesConnectionService";
import { ConnectionTable } from "../../models/SourceConnectionTable";
import Preview from "../../components/Preview.tsx/Preview";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularProgress from '@material-ui/core/CircularProgress';
import MessageBox from "../../components/message/MessageBox";
import { Alert, AlertTitle } from "@material-ui/lab";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#7CCCEE',
        color: 'white'
    },
    selectButton: {
        backgroundColor: '#349CC9',
        color: 'white'
    },
    textButton: {
        variant: "text",
        fontSize: 14,
        color: '#4e8095',
        cursor: 'pointer'
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    fullWidthField: {
        width: '100%'
    },
    checkbox: {
        height: 28,
        '&.Mui-checked': {
            color: '#4e8095',
             }
    }
}));

const options = ['Save Mapping', 'Save and Run'];

const SourcesMetaTableEditView: FC<Partial<RouteChildrenProps<{ id?: string }>>> = ({
    match,
}): ReactElement => {

    const { getClientTablesForSchema, getClientTablesConditions } = ClientTableService();

    const {
        getTables,
        saveTables,
        load
    } = SourceConnectionService();

    const { getMetaMapping } = MetaMappingService();

    const params = useParams();
    const classes = useStyles();
    const history = useHistory();

    const [mapping, setMapping] = useState<MetaMapping>();

    const [sourceTables, setSourceTables] = useState<{ tableName: string, hasPrimary: boolean, hasModified: boolean }[]>([]);
    const [sourceTablesFiltered, setSourceTablesFiltered] = useState<{ tableName: string, hasPrimary: boolean, hasModified: boolean }[]>([]);

    const [isEditing, setIsEditing] = useState(false);
    const [selectedId, setSelectedId] = useState<number | null>();
    const [search, setSearch] = useState<string>();
    const [selectedTableToPreview, setSelectedTableToPreview] = useState<string>();
    const [selectedTableName, setSelectedTableName] = useState<string>();

    const [selectedTables, setSelectedTables] = useState<{ tableName: string, hasPrimary: boolean, hasModified: boolean }[]>([]);

    const [migrating, setMigrating] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
    const [selectedTablesErrors, setSelectedTablesErrors] = useState<{ tableName: string, error: string }[]>([]);
    const [selectedTablesSuccess, setSelectedTablesSuccess] = useState<string[]>([]);
    const [migratedTables, setMigratedTables] = useState<number>();
    const [alertMessage, setAlertMessage] = useState<{ title, message, severity }>();
    const [sourceSchemaPopulated, setSourceSchemaPopulated] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [open, setOpen] = useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [savedTables, setSavedTables] = useState<{ tableName: string, hasPrimary: boolean, hasModified: boolean }[]>([]);
    const [showSaved, setShowSaved] = useState(false);

    const changeSelectedTableStatus = (table: { tableName: string, hasPrimary: boolean, hasModified: boolean }) => {
        if (!selectedTables.find(f => f.tableName === table.tableName)) {
            setSelectedTables(selectedTables.concat([table]));
        } else {
            setSelectedTables(selectedTables.filter(t => t.tableName !== table.tableName));
        }
    }

    const showProgress = (table) => {
        if (migrating) {
            if (selectedTables.includes(table)) {
                if (!selectedTablesSuccess.includes(table) || !selectedTablesErrors.find(s => s.tableName == table)) {
                    return true;
                }
            }
        }
        return false;
    }

    const saveData = async () => {
        var saved = false;

        if(sourceSchemaPopulated){
            const tablesToSave = selectedTables.map(t => {
                return {
                    name: t.tableName
                }
            });

            await saveTables({
                metaMappingId: mapping.id,
                tables: tablesToSave.map(t => t.name)
            }).then(res => {
                if (res == 'Data successfully saved') {
                    setAlertMessage({
                        message: 'Mapping successfully saved',
                        severity: 'success',
                        title: 'Mapping'
                    });                    

                    saved = true;

                    getMetaMapping((params as any).id).then(res => {
                        setMapping(res);
                        determineSelectedFromMapping(res, sourceTables);
                    });

                } else {
                    setAlertMessage({
                        message: 'Failed to save mapping',
                        severity: 'error',
                        title: 'Mapping'
                    });
                }
            }).catch(() => {
                setAlertMessage({
                    message: 'Failed to save mapping',
                    severity: 'error',
                    title: 'Mapping'
                });
            });
        }

        return saved;
    }

    const migrateData = async () => {
        setMigrating(true);
        setOpenConfirmationModal(false);

        let saved = await saveData();

        if(saved){            
            load(mapping.id).then(data => {
                if(data.success){
                    setAlertMessage({
                        message: 'Migration process started',
                        severity: 'success',
                        title: 'Data migration process'
                    });
                }else{
                    setAlertMessage({
                        message: data.error[0],
                        severity: 'error',
                        title: 'Data migration process'
                    });
                }
            }).catch(error => {
                setAlertMessage({
                    message: 'Migration failed to start',
                    severity: 'error',
                    title: 'Data migration process'
                });
            }).finally(() => {
                setMigrating(false);
            })
        }else{
            setMigrating(false);
        }
    }

    const getAndDisplayDatabaseTables = (res) => {
        try {
            getClientTablesConditions(res.clientSchema.id).then(data => {
                const tables: { tableName: string, hasPrimary: boolean, hasModified: boolean }[] = [];
                data.forEach(d => {
                    if (!tables.find(t => t.tableName == d.table_name)) {
                        tables.push({
                            tableName: d.table_name,
                            hasPrimary: d.has_primary_key,
                            hasModified: d.has_modified_date
                        })
                    }
                });

                // sort table names alphabetically
                tables.sort((a,b) => {
                    if(a.tableName > b.tableName) {
                        return 1;
                      } else if(a.tableName < b.tableName) {
                        return -1;
                      } else {
                        return 0;
                      }
                })

                setSourceTables(tables);
                setSourceTablesFiltered(tables);
                setLoading(false);

                determineSelectedFromMapping(res, tables);
            })
        } catch (error) {
            alert(error.message)
        }
    }

    const determineSelectedFromMapping = (mapping, tables) => {
        if (mapping.tables?.length) {
            let selected = tables.filter(t => mapping.tables.findIndex(tb => tb.name == t.tableName) > -1).map(f => {
                return {
                    tableName: f.tableName,
                    hasPrimary: f.hasPrimary,
                    hasModified: f.hasModified
                }
            });
            setSelectedTables(selected);
            setSavedTables(selected);
        }
    }

    const schemaIsPopulated = (res) => {
        let populated = (typeof(res.clientSchema) !== undefined && res.clientSchema != null);
        return populated;
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
     };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
     };

    const handleClick = () => {
        if(selectedIndex === 1){
            setOpenConfirmationModal(true);
        }else{
            saveData();
        }
      };
    
      const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
      };
    
      const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
      };
    
      const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
    
        setOpen(false);
      };

    useEffect(() => {
        if (params && (params as any).id) {
            getMetaMapping((params as any).id).then(res => {
                setMapping(res);
                let hasSchema = schemaIsPopulated(res);
                if (hasSchema) {
                        getAndDisplayDatabaseTables(res);
                } else {
                    setLoading(false);
                    alert("A source schema needs to be selected for this mapping");                    
                }
                setSourceSchemaPopulated(hasSchema);
            });
        }
    }, []);

    useEffect(() => {
        if(!showSaved){
            if (search) {
                setSourceTablesFiltered(sourceTables.filter(s => s.tableName.toLowerCase().includes(search.toLowerCase())));
            } else {
                setSourceTablesFiltered(sourceTables);
            }
        }else{
            if (search) {
                setSourceTablesFiltered(savedTables.filter(s => s.tableName.toLowerCase().includes(search.toLowerCase())));
            } else {
                setSourceTablesFiltered(savedTables);
            }
        }
        setPage(0);
    }, [search, showSaved, savedTables]);

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ position: 'relative' }}>
                    <div style={{ fontSize: 24, marginBottom: 20, textAlign: 'center', width: '70%' }}>
                            {mapping?.name}
                    </div>
                    <Divider />
                    <div style={{ position: 'fixed', top: 100, right: 100 }}>
                        <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                            <Button className={classes.selectButton} onClick={handleClick}>{options[selectedIndex]}</Button>
                            <Button
                                className={classes.selectButton}
                                size="large"
                                aria-controls={open ? 'split-button-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-label="select option"
                                aria-haspopup="menu"
                                onClick={handleToggle}
                            >
                                <ArrowDropDownIcon />
                            </Button>
                        </ButtonGroup>
                        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: 'bottom'
                                }}
                                >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            disabled={index === 2}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                        ))}
                                    </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}>
                        <div style={{
                            width: '50%',
                            textAlign: 'left',
                            textTransform: 'uppercase'
                        }}>
                            <div style={{ fontSize: 20, marginBottom: 20, marginTop: 10 }}>{mapping?.sourceConnectionOrigin.name}</div>
                            <div>
                                Search: <input type="text"
                                    onChange={(event) => {
                                        setSearch(event.target.value);
                                    }} />
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}>
                                <div style={{
                                    width: '50%',
                                    textAlign: 'left',
                                }}>
                                    Show Saved Selections Only <input type="checkbox"
                                    onChange={(event) => {setShowSaved(!showSaved)}} />
                                </div>
                                {/* Select all <input type="checkbox"
                                    onChange={(event) => {
                                        if (event.target.value) {
                                            setSelectedTables(sourceTablesFiltered.map(f => {
                                                return {
                                                    tableName: f.tableName,
                                                    hasPrimary: f.hasPrimary,
                                                    hasModified: f.hasModified
                                                }
                                            }));
                                        } else {
                                            const outTables = selectedTables.filter(st => sourceTablesFiltered.findIndex(sf => sf.tableName == st.tableName) == -1)
                                        }
                                    }} /> */}
                            </div>
                            <div style={{ textAlign: 'right', width: '75%' }}>
                                {loading && <CircularProgress style={{ width: 32, height: 32 }} />}
                            </div>
                            <br />
                            {sourceTablesFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(s => {
                                var selected = selectedTables.findIndex(t => t.tableName == s.tableName) > -1;
                                var hasPrimary=s.hasPrimary;
                                var hasModified=s.hasModified;
                                return (
                                    <div>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        minWidth: 400
                                    }}
                                    key={s.tableName}>
                                        <div style={{
                                        minWidth: 40
                                        }}>
                                        <Tooltip title="Preview" placement="left-start">
                                                <button
                                                    style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setSelectedTableName(s.tableName);
                                                    }}>
                                                    <VisibilityIcon color="action"/>
                                                </button>
                                            </Tooltip>
                                        </div>

                                        <div style={{
                                            minWidth: 375,
                                            height: 30,
                                            display: 'flex',
                                            flexDirection:'column',
                                            justifyContent: 'center'
                                        }}>
                                            {s.tableName}
                                        </div>                                        
                                        
                                        <div style={{
                                        minWidth: 80
                                        }}>
                                            {selected && ((!hasPrimary && <Tooltip title="No Primary Key" placement="right-start">
                                                <button
                                                    style={{ background: 'none', border: 'none', cursor: 'default' }}
                                                    >
                                                    <VpnKeyIcon style={{ color: '#dedede' }}/>
                                                </button>
                                            </Tooltip>) || <Tooltip title="Has Primary Key" placement="right-start">
                                                <button
                                                    style={{ background: 'none', border: 'none', cursor: 'default' }}
                                                    >
                                                    <VpnKeyIcon style={{ color: '#ee9e7c' }}/>
                                                </button>
                                            </Tooltip>)}

                                            {selected && ((!hasModified && <Tooltip title="No Modified Date" placement="right-start">
                                                <button
                                                    style={{ background: 'none', border: 'none', cursor: 'default' }}
                                                    >
                                                    <ChangeHistoryIcon style={{ color: '#dedede' }}/>
                                                </button>
                                            </Tooltip>) || <Tooltip title="Has Modified Date" placement="right-start">
                                                <button
                                                    style={{ background: 'none', border: 'none', cursor: 'default' }}
                                                    >
                                                    <ChangeHistoryIcon style={{ color: '#ee9e7c' }}/>
                                                </button>
                                            </Tooltip>)}                                      

                                            
                                        </div>
                                        <div style={{
                                        minWidth: 50
                                        }}></div>
                                        
                                        </div>
                                        <Divider />
                                    </div> 
                                )
                            })}
                            <Button
                                className={classes.textButton}
                                onClick={() => { history.push(`/source-connections/schemas/tables/${mapping.clientSchema.id}`) }}
                            >
                                View Schema
                            </Button>
                        </div>
                        
                        <div style={{
                            width: '50%',
                            textAlign: 'left',
                            textTransform: 'uppercase'
                        }}>
                            <div style={{ fontSize: 20, marginBottom: 26, marginTop: 10 }}>{mapping?.sourceConnectionDestination.name}</div>
                            <br />                            
                            <div >
                                {selectedTables.length} table(s) selected
                            </div>
                            <br />

                            {sourceTablesFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(s => {
                                var selected = selectedTables.findIndex(t => t.tableName == s.tableName) > -1;
                                return (
                                    <div>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    key={s.tableName}>
                                        <div style={{
                                            minWidth: 40
                                        }}>
                                            <Checkbox
                                                className={classes.checkbox}
                                                color="primary"
                                                checked={selected}
                                                    onChange={(event) => {
                                                        changeSelectedTableStatus({
                                                            tableName: s.tableName,
                                                            hasPrimary: s.hasPrimary,
                                                            hasModified: s.hasModified
                                                        });
                                                    }}
                                            />
                                        </div>
                                        <div style={{
                                            minWidth: 400,
                                            height: 30,
                                            display: 'flex',
                                            flexDirection:'column',
                                            justifyContent: 'center'
                                        }}>
                                            {(selectedTables.find(t => t.tableName == s.tableName) && mapping.prefix+s.tableName)||(<div style={{color: 'lightgrey'}}>{mapping.prefix}{s.tableName}</div>)}
                                        </div>
                                        </div>
                                        <Divider />
                                    </div>
                                )
                            })}
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                component="div"
                                count={sourceTablesFiltered?.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>

            {mapping && <Preview
                connectionId={mapping.sourceConnectionOrigin.id || null}
                tableName={selectedTableName}
                schemaId = { sourceSchemaPopulated ? mapping.clientSchema.id: null }
                onClose={() => {
                    setSelectedTableName(null);
                }}
                onError={() => {
                    setSelectedTableName(null);
                    setTimeout(() => {
                        setAlertMessage({
                            message: 'Table overview error',
                            severity: 'error',
                            title: 'There was an error trying to show the table overview.'
                        });
                    }, 200);
                }}
            />}

            {mapping && <Dialog
                open={openConfirmationModal}
                keepMounted
                onClose={() => {
                    setOpenConfirmationModal(false);
                }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{`Data Migration from ${mapping.sourceConnectionOrigin.name} to ${mapping.sourceConnectionDestination.name}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you wan't to procceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        migrateData();
                    }} color="primary"> Proceed </Button>
                </DialogActions>
            </Dialog>}

            {migratedTables && <Dialog
                open={migratedTables > 0}
                keepMounted
                onClose={() => {
                    setMigratedTables(null);
                }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{`Migration Finished`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {migratedTables} tables successfully migrated.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setMigratedTables(null);
                    }} color="primary"> Ok </Button>
                </DialogActions>
            </Dialog>}

            <MessageBox
                onOk={() => {
                    setAlertMessage(undefined);
                }}
                onWondowClose={() => {
                    setAlertMessage(undefined);
                }}
                show={alertMessage != undefined}
                title={alertMessage?.title}
            >
                <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
            </MessageBox>
        </div>
    )
}

export default SourcesMetaTableEditView;