import AppConfig from '../config';
import { ClientTable, ClientTableViewModel } from '../models/ClientTable';
import { ClientTableConditions } from '../models/ClientTableConditions';
import ApiClient from './apiClient';



const ClientTableService = () => {

    const { get, post, deleteRecords, put } = ApiClient();
    
    const getAll = () => {
        return new Promise<ClientTable[]>((resolve, reject) => {
            get<ClientTable[]>(`${AppConfig.UiApiBasePath}/client-tables`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const getRecord = (id: any) => {
        return new Promise<ClientTable>((resolve, reject) => {
            get<ClientTable>(`${AppConfig.UiApiBasePath}/client-tables/${id}`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const getClientTablesForSchema = (schemaid?: number) => {
        return new Promise<ClientTable[]>((resolve, reject) => {
            let path = `${AppConfig.UiApiBasePath}/client-tables`;
            if (schemaid) {
                path += `?schema=${schemaid}`;
            }
            get<ClientTable[]>(path).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const getClientTablesConditions = (schemaid?: number) => {
        return new Promise<ClientTableConditions[]>((resolve, reject) => {
            let path = `${AppConfig.UiApiBasePath}/client-tables-conditions`;
            if (schemaid) {
                path += `?schema=${schemaid}`;
            }
            get<ClientTableConditions[]>(path).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const save = (model: ClientTableViewModel) => {
        return new Promise<number>((resolve, reject) => {
            post<number>(`${AppConfig.UiApiBasePath}/client-tables`, model).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    return {
        getAll,
        getRecord,
        getClientTablesForSchema,
        getClientTablesConditions, 
        save
    }
}

export default ClientTableService;
