import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, Paper, Select,
     Table, TableBody, TableContainer, TableHead, TableRow, TextField, Grow, Popper, MenuList, MenuItem, ClickAwayListener } from "@material-ui/core";
import React, { FC, ReactElement } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { useEffect, useState } from "react";
import SourceConnectionService from "../../services/SourcesConnectionService";
import { useHistory, useParams } from "react-router";
import { SourceConnection } from "../../models/SourceConnection";
import ClientSchemaService from "../../services/ClientSchemaService";
import { ClientSchema } from "../../models/ClientSchema";
import { NavLink } from "react-router-dom";
import moment from 'moment'
import { DiagnosticCategory } from "typescript";
import MessageBox from "../../components/message/MessageBox";
import { Alert } from "@material-ui/lab";
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    primaryButton: {
        margin: theme.spacing(.5),
        backgroundColor: '#349CC9',
        color: 'white',
        minWidth: 125
    },
    secondaryButton: {
        margin: theme.spacing(1),
        backgroundColor: '#7CCCEE',
        color: 'white',
        fontSize: 11
    },
    textButton: {
        variant: "text",
        fontSize: 14,
        color: '#4e8095',
        cursor: 'pointer'
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    fullWidthField: {
        width: '100%'
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#7CCCEE',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const OptionsTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#7CCCEE',
        color: theme.palette.common.white,
    },
    root: {
        borderLeftWidth: 1,
        borderLeftColor: theme.palette.grey[300],
        borderLeftStyle: "solid",
      },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


const SchemaListView: FC = (): ReactElement => {

    const { getSourceConnection } = SourceConnectionService();
    const { getClientSchemasForConnection, deleteClientSchema } = ClientSchemaService();
    
    const { id } = useParams<{ id?: string }>();
    const history = useHistory();
    const classes = useStyles();
    const params = useParams() as any;
    const [loading, setLoading] = useState(false);
    const [sourceConnection, setSourceConnection] = useState<SourceConnection>();
    const [clientSchemas, setClientSchemas] = useState<ClientSchema[]>();
    const [selectedSchemaId, setSelectedSchemaId] = useState<number>();
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState<{ title, message, severity }>();

    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState<ClientSchema>();
    
    const getId = () => {
        if (id) {
            return Number.parseInt(id);
        }
        return 0;
    }

    const loadSchemas = () =>{
        setLoading(true);
        getClientSchemasForConnection(getId()).then(clientSchemas => {
            setClientSchemas(clientSchemas);
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        if (id) {
            getSourceConnection(getId()).then(connection => {
                setSourceConnection(connection);
            });
        }
    }, []);

    useEffect(() => {
        loadSchemas();
    }, [])

    useEffect(() => {
        if (selectedSchemaId) {
            setOpenConfirmationModal(true);
        }
    }, [selectedSchemaId]);

    const handleMenuToggle = (row: ClientSchema, event) => {        
        if(anchorEl !== null){
            setMenuOpen(false);            
            setAnchorEl(null);
            setCurrentRow(null);           
        }else{
            setAnchorEl(event.currentTarget);
            setCurrentRow(row);
            setMenuOpen(true);
        }
    };
    
    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };
    
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={10}>
                    <div style={{ fontSize: 20, marginBottom: 20 }}>
                        {sourceConnection?.name}
                    </div>
                </Grid>
                <Grid item xs={2} style={{textAlign: 'right'}}>
                    <Button
                        variant="contained"
                        className={classes.primaryButton}
                        //disabled={sourceConnection?.source.name != 'NetSuite'}
                        endIcon={<PlaylistAddIcon />}
                        onClick={() => {
                            if(sourceConnection?.source.name === 'NetSuite'){
                                history.push(`/source-connections/schemas/extraction/${getId()}`)
                            } else if (sourceConnection?.source.name.startsWith('Salesforce'))
                                history.push(`/source-connections/schemas/retrieval/${getId()}`)
                            else{ 
                                history.push(`/source-connections/schemas/load/${getId()}`)
                            } 
                        }}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Schema Name</StyledTableCell>
                            <StyledTableCell>Upload Date</StyledTableCell>
                            <StyledTableCell style={{width: '12%'}}></StyledTableCell>
                            <StyledTableCell style={{width: '4%'}}></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && clientSchemas && clientSchemas.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">
                                    {row.schemaName}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {moment(row.lastModified).format(`yyyy/MM/DD HH:mm:ss`)}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    <Button
                                        variant="contained"
                                        className={classes.primaryButton}
                                        endIcon={<NavigateNextIcon />}
                                        onClick={() => { history.push(`/source-connections/schemas/tables/${row.id}`) }}
                                    >
                                        View/Edit
                                    </Button>
                                </StyledTableCell>
                                <OptionsTableCell component="th" scope="row" style={{textAlign: 'center'}}>
                                    <Button
                                        //className={classes.secondaryButton}
                                        aria-controls={menuOpen ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={event => handleMenuToggle(row, event)}
                                    >
                                        <MenuIcon fontSize="medium" />
                                    </Button>
                                </OptionsTableCell>
                            </StyledTableRow>
                        ))}

                        {loading && (
                            <StyledTableRow>
                                <StyledTableCell colSpan={3} style={{textAlign: 'center'}}>Loading...</StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Popper open={menuOpen} anchorEl={anchorEl} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleMenuClose}>
                            <MenuList autoFocusItem={menuOpen} id="menu-list-grow">
                                <MenuItem onClick={() => { setSelectedSchemaId(currentRow.id) }} >Delete</MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
                )}
            </Popper>

            <Dialog
                open={openConfirmationModal}
                keepMounted
                onClose={() => {
                    setOpenConfirmationModal(false);
                    setSelectedSchemaId(null);
                }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{`Delete Schema`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {'Are you sure you want to delete ' + currentRow?.schemaName + '?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        deleteClientSchema(selectedSchemaId).then(res => {
                            if (res) {
                                loadSchemas();
                            } else {
                                setAlertMessage({
                                    message: 'This schema is being used and can`t be deleted.',
                                    severity: 'warning',
                                    title: 'Delete Error'
                                });
                            }
                        }).catch(() =>{
                            setAlertMessage({
                                message: 'Could not delete schema',
                                severity: 'error',
                                title: 'Delete Error'
                            });
                        }).finally(() => {
                            setOpenConfirmationModal(false);
                            setSelectedSchemaId(null);
                        });
                    }} color="primary"> 
                        Delete 
                    </Button>
                    <Button onClick={() => {
                        setOpenConfirmationModal(false);
                        setSelectedSchemaId(null);
                    }}
                    color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <MessageBox
                onOk={() => {
                    setAlertMessage(undefined);
                }}
                onWondowClose={() => {
                    setAlertMessage(undefined);
                }}
                show={alertMessage != undefined}
                title={alertMessage?.title}
            >
                <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
            </MessageBox>
        </div>
    )
}

export default SchemaListView;