import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { FC, ReactElement, useState } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#7CCCEE',
        color: 'white'
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    fullWidthField: {
        width: '100%'
    }
}));

interface ExtractionStartProps {
    show: boolean;
    netSuiteURL: string;
    authMessage: string;
    onWindowClose: () => void;
    onStart: (auth: string) => void;
}

const ExtractionStart: FC<ExtractionStartProps> = ({
    show = false,
    onWindowClose,
    onStart,
    netSuiteURL,
    authMessage
}): ReactElement => {

    const classes = useStyles();
    const [auth, setAuth] = useState('');

    return (
        <Dialog
            open={show}
            keepMounted
            onClose={() => {
                if (onWindowClose) {
                    onWindowClose()
                }
            }}
            aria-labelledby="extraction-dialog-slide-title"
            aria-describedby="extraction-dialog-slide-description"
        >
            <DialogTitle id="extraction-dialog-slide-title">{netSuiteURL}</DialogTitle>
            <DialogContent>
                <DialogContentText id="extraction-dialog-slide-description">
                    {authMessage}
                </DialogContentText>
                <TextField 
                    className={classes.fullWidthField} 
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(event) => {
                        setAuth(event.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    if (onStart) {
                        onStart(auth)
                    }
                }} color="primary"> Start </Button>
            </DialogActions>
        </Dialog>
        
    )
}

export default ExtractionStart;