import { Button, Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, CircularProgress } from "@material-ui/core";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { NavLink, useHistory } from "react-router-dom";
import JobsServices from "../../services/JobsServices";
import { Integration, LogTable } from "../../models/LogTable";
import moment from 'moment'
import WarningIcon from '@material-ui/icons/Warning';
import StatusService from "../../services/StatusService";
import { NoEncryptionRounded } from "@material-ui/icons";


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#7CCCEE',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    paper2: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'row',
        alignItems: 'center'
    },
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#7CCCEE',
        color: 'white',
        width: 100,
        height:22,
        marginLeft: 50
    }
  }));

const LogsListView: FC = (): ReactElement => {

    const { getAllLogs } = JobsServices();
    const { getRunning } = StatusService();

    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    
    const [jobs, setJobs] = useState<LogTable[]>();
    const [jobTotals, setJobTotals] = useState<Integration[]>();

    const [jobsInQueueList, setJobsInQueueList] = useState([]);
    const [runningJob, setRunningJob] = useState<string>();
    const [queuedJobs, setQueuedJobs] = useState<string[]>();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    
    
    useEffect(() => {
        setLoading(true);
        getAllLogs().then(res => {
            setJobTotals(res);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        getRunning().then(res => {
            setRunningJob(res.runningJob);
            setQueuedJobs(res.queuedJobs);
        })
    }, []);

    useEffect(() => {
        if (queuedJobs != undefined) {
        const jobsInQueue = queuedJobs.map((row) => (
            <li>{ row }</li>));
            setJobsInQueueList(jobsInQueue);
        }
        
    }, [queuedJobs]);

    
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
     };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
     };

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={10}>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                    <Paper className={classes.paper2}>
                        <div style={{
                            fontSize: 22,
                            color: 'grey'
                        }}>
                            {(!runningJob && 'No Jobs Currently Running') || 'Currently Running:'}
                        </div>
                        <div style={{
                            fontSize: 22,
                            color: '#4e8095',
                            marginLeft:15
                        }}>
                            {runningJob}
                        </div>

                        <Button 
                            className={classes.button}
                            variant="contained"
                            onClick={() => {
                                    setLoading(true);
                                    getRunning().then(res => {
                                        setRunningJob(res.runningJob);
                                        setQueuedJobs(res.queuedJobs);
                                    });
                                    getAllLogs().then(res => {
                                        setJobTotals(res);
                                    }).finally(() => {
                                        setLoading(false);
                                    });
                                }
                            }>
                            Refresh
                        </Button>

                    </Paper>
                </Grid>

            <Grid item xs={12}>
            <Paper className={classes.paper} style={{
                marginTop:20
            }}>
                    <div style={{
                                fontSize: 22,
                                color: 'grey'
                            }}>
                                Jobs in Queue

                    </div>
                    <div style={{
                        fontSize: 16,
                        color: 'grey',
                        marginTop: 10,
                        marginLeft:15
                    }}>
                        { (!queuedJobs || queuedJobs.length == 0 && 'No upcoming jobs') }
                    </div>
                    <ul style = {{
                        listStyle:'none',
                        color: 'grey'
                    }}>
                        { jobsInQueueList }
                    </ul>
                </Paper>
                <div style={{
                            fontSize: 22,
                            color: 'grey',
                            marginTop: 20,
                            marginBottom: 10
                        }}>
                            Jobs Log
                            { loading && <CircularProgress style = {{ 
                                marginLeft:15,
                                height:10,
                                width:10
                            }} /> }
                </div>
                
            </Grid>


            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ width: '40%'}}>Mapping Name</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell>Start Time</StyledTableCell>
                            <StyledTableCell>End Time</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && jobTotals && jobTotals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">
                                    <NavLink 
                                        to={`/logs/${row.id}`}
                                    >{row.name}</NavLink>
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.has_exception && <WarningIcon style={{ color: '#FF7A5A'}}></WarningIcon>}
                                </StyledTableCell>
                                <StyledTableCell style={{
                                    width: 200
                                }}>
                                    <div>
                                        {moment(row.start_time).utc().format('DD MMM yyyy HH:mm:ss')}
                                    </div>
                                </StyledTableCell>
                                <StyledTableCell style={{
                                    width: 200
                                }}>
                                    <div>
                                        {moment(row.end_time).utc().format('DD MMM yyyy HH:mm:ss')}
                                    </div>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                        {loading && (
                            <StyledTableRow>
                                <StyledTableCell colSpan={5} style={{textAlign: 'center'}}>Loading...</StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={jobTotals?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    )
}

export default LogsListView;