import axios, { AxiosRequestConfig } from 'axios';
import AppConfig from '../config';
import { useAuth0 } from "@auth0/auth0-react";
import App from '../App';


const ApiClient = () => {

    const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

    const getConfig = async (): Promise<AxiosRequestConfig> => {
        const config = {} as AxiosRequestConfig;
        let token;

        try {
            token = await getAccessTokenSilently({
              audience: AppConfig.ApiAudience,
              scope: AppConfig.NirvanaRequiredPermissions,
            });
            
        } catch(e) {
            if (e.error === 'login_required') {
                console.log("login_required");
                console.log(e.error);
                //loginWithRedirect();
            }
            if (e.error === 'consent_required') {
                console.log("consent_required");
                console.log(e.error);
                //loginWithRedirect();
            }
            else {
                console.error("Access token not granted." + e.error);
            }
        }

        //console.log('token: ' + token);

        config.headers = {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        return config;
    };


    const get = async <T>(url: string) => {
        let config = await getConfig();
        return axios.get<T>(url, config);
    }

    const post = async <T>(url: string, data?) => {        
        let config = await getConfig();
        return axios.post<T>(url, data, config);
    }

    const deleteRecords = async <T>(url: string) => {        
        let config = await getConfig();
        return axios.delete<T>(url, config);
    }

    const put = async <T>(url: string, data?) => {       
        let config = await getConfig();
        return axios.put<T>(url, data, config);
    }

    return {
        get,
        post,
        deleteRecords,
        put
    }


}

export default ApiClient;

