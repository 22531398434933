import AppConfig from '../config';
import { ClientColumnSimple } from '../models/ClientColumn';
import { ClientTable, ClientTableViewModel } from '../models/ClientTable';
import ApiClient from './apiClient';

const ClientColumnService = () => {    

    const { get } = ApiClient();

    const getClientColumnsForTable = (tableid?: number) => {
        return new Promise<ClientColumnSimple[]>((resolve, reject) => {
            let path = `${AppConfig.UiApiBasePath}/client-columns`;
            if (tableid) {
                path += `?table=${tableid}`;
            }
            get<ClientColumnSimple[]>(path).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    return { getClientColumnsForTable }

};

export default ClientColumnService;